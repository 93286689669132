
import { Close, Menu } from '@mui/icons-material';
import React from 'react';

const Toggle = () => {

    const togglerBar = () => {
        const a: HTMLElement | any = document.body;
        a.classList.toggle("show_header__mobail");
    };


    return (
        <button type="button" onClick={() => { togglerBar() }} className="close__btn">
            <span className='menuIcon'>  <Menu /></span>
            <span className='closeIcon'> <Close /></span>
        </button>
    );
}

export default Toggle;
