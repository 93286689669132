import React, { useEffect, useState } from "react";
// import { useState } from "../Assets/images/React App_files/bundle";

import _fetch from "../config/api";
import { api_url } from "../config/config";
import toasted from "../config/toast";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { AddCard, ArrowForward, } from "@mui/icons-material";
import Toggle from "./Components/Toggle";
import { useParams } from "react-router-dom";
import { getData } from 'country-list';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Select, { SingleValue } from 'react-select';
const EditUser = (props: any) => {
  const { user } = useParams();
  const [name, setname] = useState();
  const [email, setemail] = useState('');
  const [phone, setphone] = useState('');
  const [wallet_address, setWalletAddress] = useState('');
  const [countryLabel, setCountryLabel] = useState('');
  const [countryFlagUrl, setCountryFlagUrl] = useState('');
  const [country, setCountry] = useState<CountryOption | null>({ value: '', label: countryLabel, flagUrl: countryFlagUrl });
  const [country_flag, setcountry_flag] = useState('');
  const [withdraw_status, setwithdraw_status] = useState(false);
  const [disable, setdisable] = useState(false);



  const SubmitFund = async () => {
    let data = {
      "username": user,
      "wallet_address": wallet_address,
      'email': email,
      'phone': phone,
      'name': name,
      "country": country?.label,
      "country_flag": country?.flagUrl,
      'withdraw_status': withdraw_status,
      'disable': disable

    }

    let res = await _fetch(`${api_url}profile/updateUserProfile`, "POST", data)
    if (res?.status === "success") {
      toasted.success(res?.message);
      getUserAllData()
    }
    else {
      toasted.error(res?.message);
    }
  }



  const [allData, setAllData] = useState({ TotalDeposit: 0, allUsers: 0, AvaliableDeposit: 0 });

  useEffect(() => {
    getUserAllData();
  }, []);

  const getUserAllData = async () => {


    let data = await _fetch(`${api_url}profile/getUser?username=${user}`, "GET", {})
    if (data?.status === "success") {


      setAllData(data?.data);
      setWalletAddress(data?.data?.userInfo?.wallet_address)
      setemail(data?.data?.userInfo?.email);
      setphone('+' + data?.data?.userInfo?.phone);
      setCountry({
        value: '',
        label: data?.data?.userInfo?.country,
        flagUrl: data?.data?.userInfo?.country_flag
      });
      setname(data?.data?.userInfo?.name);
      setwithdraw_status(data?.data?.userInfo?.withdraw_status);
      setdisable(data?.data?.userInfo?.disable);


    }
  }


  type CountryOption = {
    value: string;
    label: string;
    flagUrl: string;
  };

  const CustomOption: React.FC<{ innerProps: any; label: string; data: CountryOption }> = ({ innerProps, label, data }) => (
    <div {...innerProps}>
      <img src={data.flagUrl} alt={label} style={{ marginRight: '8px' }} />
      {label}
    </div>
  );

  const countryOptions: CountryOption[] = getData().map((country: any) => ({
    value: country.code,
    label: country.name,
    flagUrl: `https://flagcdn.com/16x12/${country.code.toLowerCase()}.png`
  }));

  return (
    <div>
      <div>
        <div className="coustom_container">
          <div className="page_info">
            <div className="left__data">
              <h6> <AddCard /> {props.pageTitle}</h6>
              <p>Dashboard / Deposit / <span> {props.pageTitle}  </span></p>
            </div>

            <div className="right_data">
              <Toggle />
            </div>
          </div>

          <div className="edit__user">
            <div className="row">
              <div className="col-lg-6 col-md-8">
                <div className="card">
                  <div className="__editgroup">
                    <label htmlFor="userID">Name</label>
                    <input className="input-fields" id="userID" type="text" placeholder="Name" onChange={(e: any) => setname(e.target.value)} value={name} />
                  </div>

                  <div className="__editgroup">
                    <label htmlFor="pins">Email</label>
                    <input className="input-fields" type="email" placeholder="Email Address" onChange={(e: any) => setemail(e.target.value)} value={email} />
                  </div>
                  

                  <div className="__editgroup">
                    <label className="form-label form_lable">Phone </label>
                    <PhoneInput
                      inputStyle={{ height: '45px', padding: '0px 20px 0px 50px', outline: 'none', color: '#000', width: '100%', fontSize: '14px' }}
                      containerStyle={{}}
                      searchClass="search-class"
                      disableSearchIcon={false}
                      enableTerritories
                      countryCodeEditable={true}
                      placeholder='Phone Number'
                      buttonStyle={{ width: '47px' }}
                      dropdownStyle={{ height: '300px' }}
                      country={"in"}
                      value={phone}
                      onChange={(value: string) => setphone(value)}
                      enableSearch={true} />
                  </div>

                  <div className="__editgroup">
                    <label className="form-label">{("Country")}</label>
                    <Select
                      options={countryOptions}
                      value={country}
                      onChange={(option: SingleValue<CountryOption>) => setCountry(option)}
                      components={{ Option: CustomOption }}
                      isSearchable
                      placeholder="Select a country..."
                    />

                  </div>

                  <div className="__editgroup">
                    <label htmlFor="pins">Wallet Address</label>
                    <input className="input-fields" type="email" placeholder="BEP20 Wallet Address" onChange={(e: any) => setWalletAddress(e.target.value)} value={wallet_address} />
                  </div>


                  <div className="__editgroup">
                    <label htmlFor="withdraw">Withdraw Status:</label>
                    <select name="withdraw" id="withdraw" className="form-control" onChange={(e: any) => { setwithdraw_status(e.target.value) }} >
                      <option value="false" selected={withdraw_status == false ? true : false}>Disable</option>
                      <option value="true" selected={withdraw_status == true ? true : false}>Enable</option>
                    </select>
                  </div>

                  <div className="__editgroup">
                    <label htmlFor="disble">User Disable:</label>
                    <select name="disble" id="disble" className="form-control" onChange={(e: any) => { setdisable(e.target.value) }} >
                      <option value="false" selected={disable == false ? true : false}>Disable</option>
                      <option value="true" selected={disable == true ? true : false}>Enable</option>
                    </select>
                  </div>




                  <div className="__editgroup m-0">
                    <button type="submit" className="btn btn__submit" onClick={SubmitFund}> Save </button>
                  </div>


                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default EditUser;
