import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { AttachMoneyRounded, HomeTwoTone, Newspaper } from "@mui/icons-material";
import card_1 from "../Assets/images/sales-graph-1-1a977117-svg.svg";
import _fetch from "../config/api";
import { api_url } from "../config/config";
import dateformat from "dateformat";
import toasted from "../config/toast";
import LoadingBar from 'react-top-loading-bar';
import Toggle from "./Components/Toggle";


const News = (props: any) => {

  const [newsData, setNewsData] = useState<{ title?: string, description?: string, created_at?: string }>({});
  const [newsTitle, setNewsTitle] = useState('');
  const [newsDesc, setNewsDesc] = useState('');
  const [statusValue, setStatusValue] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isTrue, setIsTrue] = useState(true);
  const [loading, setLoading] = useState(true);
  const [statusChange, setStatusChange] = useState(false);

  useEffect(() => {
    getNewsData();
  }, []);

  const getNewsData = async () => {

    setIsTrue(true)
    setProgress(10);

    let res = await _fetch(`${api_url}settings/news`, "GET", {})
    if (res?.status === "success") {

      // setProgress(50);
      setNewsData(res?.data[0]);
      setNewsTitle(res?.data[0]?.title);
      setNewsDesc(res?.data[0]?.description);
      setStatusChange(res?.data[0]?.status || false);
      setProgress(100);
      setIsTrue(false);
    }
  }

  const SubmitNews = async () => {
    let data = {
      "title": newsTitle,
      "description": newsDesc
    }
    let res = await _fetch(`${api_url}settings/setupNews`, "POST", data)
    if (res?.status === "success") {
      toasted.success(res?.message);
      getNewsData();
    }
    else {
      toasted.error(res?.message);
    }
  }

  const handleStatusChange = () => {

    setStatusChange((prevStatus) => {
      const newStatus = !prevStatus;
      changeStatus(newStatus);
      return newStatus;
    });
  };

  const changeStatus = async (newStatus: boolean) => {

    let data = {
      "status": newStatus
    }
    let res = await _fetch(`${api_url}settings/setupNewsStatus`, "POST", data)
    if (res?.status === "success") {
      toasted.success(res?.message);
    }
    else {
      toasted.error(res?.message);
    }
  }

  return (
    <>

      <LoadingBar
        color='var(--primary-color)'
        progress={progress}
        containerClassName="LoadingBar_contain"
        className="loaderbar-s"
        onLoaderFinished={() => setProgress(0)}
      />

      <div className={isTrue ? 'loader-bg' : ''}></div>

      <div className="d-details-user">
        <div className="coustom_container">

          <div className="page_info">
            <div className="left__data">
              <h6> <Newspaper /> {props.pageTitle}</h6>
              <p>Dashboard / Settings / <span>  News</span></p>
            </div>

            <div className="right_data">
              <Toggle />
            </div>
          </div>

          <div className="tabl-news-card">
            <div className="row m-0">
              <div className="col-md-4">

                <div className="card first-card">
                  <div className="card-header">
                    <div className="card-imgs">
                      <img src={card_1} alt="icon" />
                      <AttachMoneyRounded className="icon payout" />
                    </div>
                    <div className="card-ryt-text">
                      <p>News Information</p>
                    </div>
                  </div>

                  <div className="">
                    <form>
                      <div className="form-group mb-3 mt-3">
                        <label htmlFor="newsTItle">News title</label>
                        <input type="text" className="form-control" id="newsTItle" placeholder="News Title" name="newsTItle" onChange={(e: any) => setNewsTitle(e.target.value)} value={newsTitle} />
                      </div>

                      <div className="form-group mt-3 mb-3">
                        <label htmlFor="News">News</label>
                        <textarea className="form-control" id="exampleFormControlTextarea1" rows={3} onChange={(e: any) => setNewsDesc(e.target.value)} value={newsDesc}></textarea>
                      </div>

                      <div className="">
                        <button type="button" className="btn btn-news" onClick={SubmitNews}>Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="outer-table table-responsive ps-md-4">
                  <div className="t-head">
                    <div className="t-head-left">
                      <h5>News</h5>
                      <div className="toggle-sec">
                        <label className="switch">
                          <input type="checkbox" className="switch-input" onChange={(e: any) => handleStatusChange()} checked={statusChange} />
                          <span className="switch-label" data-on="ON" data-off="OFF"></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                    </div>

                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>News</th>
                        <th>Created at	</th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr>
                        <td>
                          <div className="">
                            <h3 className="news-sec">{newsData?.title}</h3>
                            <p>{newsData?.description}</p>
                          </div>
                        </td>
                        <td className="date-sec text-nowrap"> {dateformat(newsData?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



    </>
  );
};

export default News;
