import React, { useEffect, useState } from "react";
import _fetch from "../config/api";
import { api_url, websiteUser } from "../config/config";
import dateformat from "dateformat";
import ReactPaginate from "react-paginate";
import card_1 from "../Assets/images/sales-graph-1-1a977117-svg.svg";
import { FiberManualRecord, HomeTwoTone, PersonRounded } from "@mui/icons-material";
import LoadingBar from 'react-top-loading-bar';
import toasted from "../config/toast";
import { Link } from "react-router-dom";
import Toggle from "./Components/Toggle";


const Users = (props: any) => {

  const [searchedValue, setSearchedValue] = useState("");
  const [searchedType, setSearchedType] = useState("username");
  const [tableRecords, settableRecords] = useState([]);
  const [itemOffset, setItemOffset] = useState(1);
  const [allDetail, setAllDetail] = useState({ totalUsers: "", activeUsers: "", inactiveUsers: "", blockUsers: "" });
  const [totalData, setTotalData] = useState(0);
  const [sort, setSort] = useState('created_at');
  const [limit, setLimit] = useState(10);
  const [keyCount, setKeycount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isTrue, setIsTrue] = useState(true);
  const [check, setcheck] = useState(false);

  useEffect(() => {
    getData();  

  }, [searchedType, sort, searchedValue, itemOffset, limit]);

  async function getData() {

    setIsTrue(true);
    setProgress(10);

    let searchData = '';
    if (searchedValue !== "" && searchedValue != undefined) {
      searchData = `&search=${searchedType}&value=${searchedValue}`;
    }
    else {
      searchData = "";
    }

    if (searchData) {
      const Data = await _fetch(`${api_url}report/users?sort=${sort}${searchData}`, 'GET', {});
      if (Data?.status === "success") {
        // setProgress(50);
        settableRecords(Data?.data?.data);
        setAllDetail(Data);
        setTotalData(Data?.total);
        setProgress(100);
        setIsTrue(false);
      }
      if (itemOffset > 1) {
        setKeycount(0);
        var count = itemOffset - 1;
        setKeycount(count * limit);
      }
    }
    else {
      const Data = await _fetch(`${api_url}report/users?sort=${sort}&page=${itemOffset}&limit=${limit}${searchData}`, 'GET', {});
      if (Data?.status === "success") {

        // setProgress(50);
        settableRecords(Data?.data?.data);
        setAllDetail(Data);
        setTotalData(Data?.total);
        setProgress(100);
        setIsTrue(false);
      }
      if (itemOffset > 1) {
        setKeycount(0);
        var count = itemOffset - 1;
        setKeycount(count * limit);
      }
    }
  }

  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setItemOffset(newOffset);
  };

  const blockUser = async(userID: any) => {
    
    
    // let data = {

    //   "title": "newsTitle",
    // }
    // let res = await _fetch(`${api_url}settings/setupNews`, "POST", data)
    // if (res?.status === "success") {
    //   toasted.success(res?.message);
    // }
    // else {
    //   toasted.error(res?.message);
    // }
  }

  return (
    <div>

      <LoadingBar
        color='var(--primary-color)'
        progress={progress}
        containerClassName="LoadingBar_contain"
        className="loaderbar-s"
        onLoaderFinished={() => setProgress(0)}
      />

      <div className={isTrue ? 'loader-bg' : ''}></div>

      <div className="table-sec pb-4">
        <div className="table-sec-subheader">

          {/* user details...  */}

          <div className="details-sec">
            <div className="coustom_container">

            <div className="page_info">
              <div className="left__data">

                <h6> <HomeTwoTone /> {props.pageTitle}</h6>
                <p>Dashboard /<span> Participants</span></p>

              </div>

              <div className="right_data">
                <Toggle />
              </div>
            </div>

              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        All Participants
                      </p>
                    </div>
                    <div className="text-start">
                      <h4 className="active-txt"> <span className="de-sc"></span> {allDetail?.totalUsers} <span className="users-txt">Users</span></h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Active Participants
                      </p>
                    </div>
                    <div className="text-start bg-success">
                      <h4 className="s-success"> <span className="de-sc"></span> {allDetail?.activeUsers} <span className="users-txt">Users</span></h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Inactive Participants
                      </p>
                    </div>
                    <div className="text-start bg-warning">
                      <h4> <span className="de-sc"></span> {allDetail?.inactiveUsers} <span className="users-txt">Users</span></h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Blocked Participants
                      </p>
                    </div>
                    <div className="text-start bg-danger">
                      <h4> <span className="de-sc"></span> {allDetail?.blockUsers} <span className="users-txt">Users</span></h4>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>




        <div className="coustom_container mb-5">
          <div className="table_main table_main_new">
            <div className="heading">
              <h1>All Participants</h1>
              <form action="">
                <div className="form-group">
                  <select className="form-select" onChange={(e: any) => setSearchedType(e.target.value)}>
                    <option value={'username'}>Username</option>
                    <option value={'sponsor_id'}>Sponsor ID</option>
                    <option value={'name'}>Name</option>
                    <option value={'email'}>Email</option>
                    <option value={'phone'}>Phone Number</option>
                  </select>
                </div>
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="search"
                    placeholder="Search"
                    onChange={(e: any) => setSearchedValue(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="table-sec-new">
              <div className="outer-table table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col"></th>
                      <th scope="col">Username</th>
                      <th scope="col">Password</th>

                      <th scope="col">Name</th>
                      <th scope="col">Phone</th>
                      <th scope="col">Email</th>
                      <th scope="col">Sponsor ID</th>
                      <th scope="col">Directs</th>
                      <th scope="col">Level Bonus</th>
                      <th scope="col">Club Bonus</th>

                      <th scope="col">Direct Business</th>
                      <th scope="col">Team Business</th>
                      <th scope="col">Package</th>
                      <th scope="col">Joining Date</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tableRecords?.map((val: any, key: any) => {
                        return (
                          <tr>
                        
                            <td>{itemOffset > 1 ? key + keyCount + 1 : key + 1}</td>
                            <td>
                            <a href={websiteUser+`admin-login/`+val?.email+`/`+val?.origin_password} target="_blank"> Login</a><span className="d-inline-block ms-1 me-1">|</span>
                            <Link to={`/edit-user/`+val?.username} className="bg-info" > Edit User</Link> |
                            <Link to={`/edit-password/`+val?.username} className="bg-info" > Edit Password</Link> </td>

                            <td>{val?.username}</td>
                            <td>{val?.origin_password}</td>
                            <td>{val?.name}</td>
                            <td>{val?.phone}</td>
                            <td>{val?.email}</td>
                            <td>{val?.sponsor_id}</td>
                            <td>{val?.directs.active}</td>
                            <td>{val?.income_info.club_fund?.toFixed(2)}</td>
                            <td>{val?.income_info.level_income?.toFixed(2)}</td>

                            <td>{val?.business?.direct_business?.toFixed(2)}</td>
                            <td>{val?.business?.team_business?.toFixed(2)}</td>
                            <td>${val?.package}</td>
                            <td> {dateformat(val?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
              {totalData > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                pageCount={totalData / 10}
                previousLabel="<"
              />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Users;
