import React, { useEffect, useState } from "react";
import _fetch from "../config/api";
import { ReStakeContractAbi, ReStakeContractAddress, StakeContractAbi, StakeContractAddress, WithdrawContractAbi, WithdrawContractAddress, api_url, blackChainUrl, contractAbi, contractAddress, sellContractAbi, sellContractAddress, supportedChainId } from "../config/config";
import dateformat from "dateformat";
import ReactPaginate from "react-paginate";
import card_1 from "../Assets/images/sales-graph-1-1a977117-svg.svg";
import { CheckCircleOutline, Deselect, FiberManualRecord, PersonRounded } from "@mui/icons-material";
import LoadingBar from 'react-top-loading-bar';
import { useParams } from 'react-router-dom';
import { InjectedConnector } from "@web3-react/injected-connector";
import Web3 from 'web3';
import detectEthereumProvider from '@metamask/detect-provider';
import toasted from "../config/toast";
import { Tooltip } from "@mui/material";
import Toggle from "./Components/Toggle";

const PendingWithdraw = (props) => {

  const [searchedValue, setSearchedValue] = useState("");
  const [searchedType, setSearchedType] = useState("username");
  const [tableRecords, settableRecords] = useState([]);
  const [itemOffset, setItemOffset] = useState(1);
  const [allDetail, setAllDetail] = useState({ totalWithdraw: "", todayWithdrawAmount: "", totalApprovedWithdraw: "", totalPendingWithdraw: "" });
  const [totalData, setTotalData] = useState(0);
  const [sort, setSort] = useState('created_at');
  const [limit, setLimit] = useState(10);
  const [keyCount, setKeycount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isTrue, setIsTrue] = useState(true);
  const [type, setType] = useState(false);
  const [text, setText] = useState('');

  useEffect(() => {
    getData();
  }, [searchedType, sort, searchedValue, itemOffset, limit]);

  const [selectedIds, setSelectedIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const toggleSelectAll = (selectAll) => {
    const allIds = tableRecords.map((val) => { return { 'id': val?._id, 'amount': val.payable_amount, 'wallet_address': val.wallet_address } });
    // console.log('allIds',allIds);
    setSelectedIds(selectAll ? allIds : []);

  };
  const handleCheckboxChange = (id, payable_amount, wallet_address, isChecked) => {

    if (selectAll) {
      return;
    }
    const obj = { 'id': id, 'amount': payable_amount, 'wallet_address': wallet_address };

    // if (selectedIds?.length == 0) {
    //   setSelectedIds([obj]);
    // } else {
    //   console.log('isChecked', isChecked);
    // if (isChecked) {

    if (selectedIds.filter(selectedId => selectedId.id == id)?.length == 0) {

      setSelectedIds([...selectedIds, obj]);

    } else {
      setSelectedIds(selectedIds.filter(selectedId => selectedId.id != id));
    }
    // }
    // }

  };

  // const [selectedItems, setSelectedItems] = useState([]);

  // const handleSelectAll = () => {
  //   setSelectedItems(tableRecords.map(item => item._id));
  //   console.log('selectedItems all',selectedItems);

  // };

  // const handleUnselectAll = () => {
  //   setSelectedItems([]);
  // };

  // const handleToggleItem = (_id) => {
  //   if (selectedItems.includes(_id)) {
  //     setSelectedItems(selectedItems.filter(id => id !== _id));
  //   } else {
  //     setSelectedItems([...selectedItems, _id]);
  //   }
  //   console.log('selectedItems single',selectedItems);
  // };


  async function getData() {

    setIsTrue(true);
    setProgress(10);

    let searchData = '';
    if (searchedValue !== "" && searchedValue != undefined) {
      searchData = `&search=${searchedType}&value=${searchedValue}`;
    }
    else {
      searchData = "";
    }

    const Data = await _fetch(`${api_url}payout/pendingPayouts?sort=${sort}&page=${itemOffset}&limit=${limit}${searchData}`, 'GET', {});
    if (Data?.status === "success") {

      settableRecords(Data?.data?.data);
      setAllDetail(Data);
      setTotalData(Data?.total);
      setProgress(100);
      setIsTrue(false);
    }
    if (itemOffset > 1) {
      setKeycount(0);
      var count = itemOffset - 1;
      setKeycount(count * limit);
    }
  }

  const handlePageClick = async (event) => {
    const newOffset = (event.selected + 1);
    setItemOffset(newOffset);
  };

  /////////////dappppppppp/////////////////////////////////


  ////////////////// dapp things //////////

  const [web3, setWeb3] = useState(null);
  const [account, setAccount] = useState(null);
  const [coinBalance, setCoinBalance] = useState(0);
  const [tokenBalance, setTokenBalance] = useState(0);

  const [disconnectStataus, setdisconnectStataus] = useState(false);
  const [tokenContract, setTokenContract] = useState(null);
  const [StakeContract, setStakeContract] = useState(null);
  const [ReStakeContract, setReStakeContract] = useState(null);
  const [WithdrawContract, setWithdrawContract] = useState(null);
  const [sellContract, setsellContract] = useState(null);


  const [leftCoinBalance, setleftCoinBalance] = useState(0);

  const [web3Useff, setWeb3UseEff] = useState(0);


  const connectWallet = async () => {
    console.log('asdasd', web3);
    setdisconnectStataus(false)

    // setWeb3UseEff(web3Useff ==0 ? 1:0);
    // setdisconnectStataus(false)
    if (web3) {
      try {
        web3.eth.getChainId().then(async chainIdd => {
          // console.log(chainIdd)
          const chainId = chainIdd.toString().slice(0, -1);
          const hexChainId = await web3.utils.toHex(chainId);
          const checkChain = await supportedChainId.includes(hexChainId);
          // console.log(checkChain, hexChainId);
          // const newChain = chainId.toString().slice(0,-1);
          if (checkChain === false) {
            // console.log("test");
            switchNetwork(supportedChainId[0])
          }
        }

        );

        // console.log('chainId',chainId);
        await web3.currentProvider.enable(); // Prompt user to connect
        const accounts = await web3.eth.getAccounts();
        if (accounts.length > 0) {
          setAccount(accounts[0]);
          // console.log('accounts[0]',accounts[0]);
          getCoinBalances(web3, accounts);
          getTokenBalances(web3, accounts);
          // console.log(formattedCoinBalance);
        }
        // setAccount(accounts[0]);
      } catch (error) {
        // console.error('Error connecting wallet:', error);
      }
    } else {
      // console.log('web else');
    }
  };

  const switchNetwork = async (receiveChainId) => {
    if (web3) {
      try {
        // console.log(receiveChainId);
        await web3.currentProvider.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: receiveChainId.toString(), // BSC Testnet chain ID
              chainName: 'Binance Smart Chain',
              nativeCurrency: {
                name: 'BNB',
                symbol: 'BNB',
                decimals: 18,
              },
              rpcUrls: ['https://bsc-dataseed.binance.org/'], // Testnet RPC URL
              blockExplorerUrls: ['https://bscscan.com'], // Testnet explorer URL
            },
          ],
        });

        // The user might need to reconnect their wallet after switching networks
      } catch (error) {
        // console.error('Error switching network:', error);
      }
    }
  };



  const getCoinBalances = async (web3Instance, accounts) => {
    const getCoinBalance = await web3Instance.eth.getBalance(accounts[0]);
    const formattedCoinBalance = web3Instance.utils.fromWei(getCoinBalance, 'ether');
    if (formattedCoinBalance > 0) {
      setCoinBalance(formattedCoinBalance);
    }
  }

  const getTokenBalances = async (web3Instance, accounts) => {
    const contract = new web3Instance.eth.Contract(contractAbi, contractAddress);
    setTokenContract(contract);

    // const currency = await contract.methods.symbol().call();
    // const getTokenBalance = await contract.methods.balanceOf(accounts[0]).call();
    // const tokenDecimals = await contract.methods.decimals().call();
    // const tokenDecimalsMain = await parseInt(tokenDecimals.toString().replace("n", ""));
    // const formattedBalance = getTokenBalance.toString().replace("n", "") / (10 ** tokenDecimalsMain);

    // if (formattedBalance > 0) {
    //   setTokenBalance(formattedBalance);
    // }



    const tokenContractInstance = new web3Instance.eth.Contract(StakeContractAbi, StakeContractAddress);
    // console.log(tokenContractInstance);
    setStakeContract(tokenContractInstance);

    const tokenBalance = new web3Instance.eth.Contract(contractAbi, contractAddress);
    const _balance = await tokenBalance.methods.checkBalance().call();
    const leftBalance = _balance.toString().replace("n", "") / (10 ** 18);
    setleftCoinBalance(leftBalance);
    // console.log(leftBalance);
    // setReStakeContract(RestaketokenContractInstance);
    // const withdrawContractInstance = new web3Instance.eth.Contract(WithdrawContractAbi, WithdrawContractAddress);

    // setWithdrawContract(withdrawContractInstance);
    // const sellContractInstance = new web3Instance.eth.Contract(sellContractAbi, sellContractAddress);

    // setsellContract(sellContractInstance);
  }





  useEffect(() => {
    // registerUser()
    connectWallet();
    if (disconnectStataus !== true) {
      const loadWeb3 = async () => {
        const provider = await detectEthereumProvider() || new InjectedConnector({ supportedChainIds: supportedChainId });

        if (provider) {
          if (provider.isMetaMask) {
            provider.autoRefreshOnNetworkChange = false;
          }
          // console.log('set web 3');
          const web3Instance = new Web3(provider);
          setWeb3(web3Instance);

          // 

          provider.on('accountsChanged', (newAccounts) => {
            if (newAccounts.length === 0) {
              // User disconnected wallet
              // setWeb3(null);
              setAccount(null);
            } else {
              setAccount(newAccounts[0]);
              // console.log('newAccounts[0]',newAccounts[0]);
              // getCoinBalances(web3Instance, newAccounts);
              // getTokenBalances(web3Instance, accounts);


            }
          });

          // 

          const accounts = await web3Instance.eth.getAccounts();
          if (accounts.length > 0) {
            setAccount(accounts[0]);
            // console.log('accounts[0]',accounts[0]);
            // getCoinBalances(web3Instance, accounts);
            // getTokenBalances(web3Instance, accounts);
            // console.log(formattedCoinBalance);
          }


        }
      };

      loadWeb3();
    }
  }, [account, disconnectStataus]);

  const disconnectWallet = async () => {
    // console.log('dis', web3);
    localStorage.clear();

    // if (web3) {
    //   web3.providers.HttpProvider.prototype.disconnect();
    setWeb3(null);
    setAccount(null);
    // }
    setdisconnectStataus(true)

    //     const ethereum = window.ethereum
    //       if (ethereum) {
    //       // Listening to Event
    //       ethereum.on('disconnect', () => {
    // console.log("MetaMask discconnected")
    //         // this.setState({ metamaskHasDisonnected: true })
    //   })
    // }
  };


  /////////////////dappp////////////////////////////////////





  const [amount, setAmount] = useState("");
  const [getSopnserWallet, setgetSopnserWallet] = useState('');
  const [gethash, setGethash] = useState('');

  const [approveLoader, setapproveLoader] = useState('0');
  const [changeSection, setchangeSection] = useState(false);
  const [activeLogin, setactiveLogin] = useState(false);
  const [btnDisable, setbtnDisable] = useState(false);


  const approveAndSendTokens = async (ids, amounts, addresses) => {
    // if (amount >= 50) {
    setbtnDisable(true)

    // const web3 = props?.maskData?.web3;
    // const account = props?.maskData?.address;
    // const tokenContract = props?.maskData?.tokenContract;
    // const StakeContract = props?.maskData?.StakeContract;
    // console.log('web3', web3);
    // console.log('account', account);
    // console.log('tokenContract', tokenContract);
    // console.log('StakeContract', StakeContract);

    if (web3 && account && tokenContract) {
      setapproveLoader('1')

      // const approvalAmount = web3.utils.toWei(`${amount}`, 'ether'); // Replace with the desired approval amount
      try {
        // const _approvalGas = await tokenContract.methods.approve(StakeContractAddress, approvalAmount).estimateGas({
        //   from: account,
        // });


        // const approvalGas = await parseInt(_approvalGas.toString().replace("n", ""));
        const _gasPrice = await web3.eth.getGasPrice();
        const gasPrice = await parseInt(_gasPrice.toString().replace("n", ""));
        // console.log('gasPrice sadasd', gasPrice, approvalAmount);

        const gasPriceGwei = gasPrice;
        const gasPriceWei = Math.floor(gasPriceGwei * 1.3);

        // const approvalClear = await tokenContract.methods.approve(StakeContractAddress, approvalAmount).send({
        //   from: account,
        //   gasPrice: gasPriceWei.toString(),
        //   gasLimit: approvalGas
        // }).once('transactionHash', function (hash) {
        //   // console.log('approval hash: ', hash);

        // }).on('error', function (error) {
        //   // console.log('\n[ERROR]\n\n' + error
        //   //   .message);
        //   let msg = error.message.split(":");
        //   // toastr.error("Error " + error.code +
        //   //     " : " + msg[1]);
        // }).then(async function (receipt) {
        setapproveLoader('2')
        setchangeSection(false)
        console.log(ids, amounts, addresses);
        const getEstimatedGas = await tokenContract.methods.withdrawMultiple(ids, amounts, addresses).estimateGas({ from: account });
        console.log(getEstimatedGas);
        const estimatedGas = await parseInt(getEstimatedGas.toString().replace("n", ""));

        // console.log('gasPrice', gasPrice, estimatedGas, approvalAmount);
        const _gasPriceGwei = gasPrice;
        const _gasPriceWei = Math.floor(_gasPriceGwei * 1.3);

        await tokenContract.methods.withdrawMultiple(ids, amounts, addresses).send({
          from: account,
          gasPrice: _gasPriceWei.toString(),
          gasLimit: estimatedGas
        }).once('transactionHash', function (hash) {
          console.log('transactionHash hash: ', hash);

          console.log(hash);
          // setGethash(hash)
          setchangeSection(true)
        }).on('error', function (error) {
          console.log('\n[ERROR]\n\n' + error
            .message);
          let msg = error.message.split(":");
          // toastr.error("Error " + error.code +
          //     " : " + msg[1]);
        }).then(async function (receipt) {
          // call node apiLOG

          console.log('receipt', receipt);
          approvedSubmit(ids, receipt.transactionHash)
          setactiveLogin(true)
          // setGethash(receipt.transactionHash)

        })
        // })

        console.log('Tokens approved and sent successfully');
      } catch (error) {
        console.error('Error approving and sending tokens:', error);
        toasted.error(error?.data?.message);
        setbtnDisable(false)
        setapproveLoader('3')


      }
    }
    // } else {
    //   toasted.error('Please enter amount minimum $50');

    // }
  };



  const SubmitData = async () => {
    if (text != "" && text != null) {
      var idArr = [];
      var amountArr = [];
      var walletArr = [];


      selectedIds?.map((val, key) => {
        idArr[key] = val.id;
        amountArr[key] = web3.utils.toWei(`${val.amount}`, 'ether');
        walletArr[key] = val.wallet_address;
      })
      console.log('type', type);
      if (type == 'true') {

        approveAndSendTokens(idArr, amountArr, walletArr);
      } else {
        let data = {
          "_id": idArr,
          "withdraw_status": type,
          "remark": text,
          "hash": '',
        }
        // console.log('asdasdsa erroe');
        let res = await _fetch(`${api_url}payout/payoutAccessAdmin`, "POST", data)
        if (res?.status === 'success') {
          toasted.success(res?.message);
        } else {
          toasted.error(res?.message);
        }
      }
    } else {
      toasted.error('Please enter remark!');
    }
  }

  const approvedSubmit = async (ids, hash) => {
    let data = {
      "_id": ids,
      "withdraw_status": type,
      "remark": text,
      "hash": hash,
    }
    // console.log('idArr', idArr);
    let res = await _fetch(`${api_url}payout/payoutAccessAdmin`, "POST", data)
    if (res?.status === 'success') {
      toasted.success(res?.message);
    } else {
      toasted.error(res?.message);
    }
  }


  return (
    <div>

      <LoadingBar
        color='var(--primary-color)'
        progress={progress}
        containerClassName="LoadingBar_contain"
        className="loaderbar-s"
        onLoaderFinished={() => setProgress(0)}
      />

      <div className={isTrue ? 'loader-bg' : ''}></div>

      <div className="table-sec pb-4">
        <div className="table-sec-subheader">

          {/* user details...  */}

          <div className="details-sec">
            <div className="coustom_container">

            <div className="page_info">
                <div className="left__data">
                  <h6> <PersonRounded /> {props.pageTitle}</h6>
                  <p>Dashboard / Withdraw / <span> {props.pageTitle}  </span></p>
                </div>

                <div className="right_data">
                  <Toggle />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Total Payout
                      </p>
                    </div>
                    <div className="text-start pt-1">
                      <h4 className="active-txt"> <span className="de-sc"></span> ${Number(allDetail?.totalWithdraw)?.toFixed(2)}</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Today Payout
                      </p>
                    </div>
                    <div className="text-start bg-info">
                      <h4> <span className="de-sc"></span> ${Number(allDetail?.todayWithdrawAmount)?.toFixed(2)} </h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Approved Payout
                      </p>
                    </div>
                    <div className="text-start bg-success">
                      <h4> <span className="de-sc"></span> ${Number(allDetail?.totalApprovedWithdraw)?.toFixed(2)}</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Pending Payout
                      </p>
                    </div>
                    <div className="text-start bg-warning">
                      <h4> <span className="de-sc"></span> ${Number(allDetail?.totalPendingWithdraw)?.toFixed(2)} </h4>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>

        <div className="coustom_container mb-5">
          <div className="table_main table_main_new">
            <div className="heading heading__new">
              <h1>Pending Payout List</h1>
              {/* <div className="d-flex align-items-center gap-2"> </div> */}



              <div className="buttons-s__nds pe-2">


                <form action="">
                  <p className="mb-0">{leftCoinBalance.toFixed(2)}USDT</p>
                  <div className="form-group">
                    <select className="form-select" onChange={(e) => setSearchedType(e.target.value)}>
                      <option value={'username'}>Username</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <input
                      className="input-fields"
                      type="search"
                      placeholder="Search"
                      onChange={(e) => setSearchedValue(e.target.value)} />
                  </div>
                </form>
                <div className="btn__sdcsds">


                  <button className="btn btn-success" onClick={() => toggleSelectAll(true)}>
                    <Tooltip title="Select All"> <CheckCircleOutline /> </Tooltip>
                  </button>

                  <button className="btn btn-2 btn-danger ms-2" onClick={() => toggleSelectAll(false)}>
                    <Tooltip title="Unselect All">  <Deselect /> </Tooltip>
                  </button>

                  <button type="button" className="Connect__wallet_button" onClick={() => connectWallet()}> {account ? ' Connected Wallet' : ' Connect Wallet'}</button>
                </div>

              </div>
            </div>

            <div className="table-sec-new">
              <div className="outer-table table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col"></th>
                      <th scope="col">Request No.</th>
                      <th scope="col">Username</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Deduction</th>
                      <th scope="col">Payable Amount</th>
                      <th scope="col">Request Date</th>
                      {/* <th scope="col">Remark</th> */}
                      {/* <th scope="col">Paid Date</th> */}
                      <th scope="col">Wallet Address</th>
                      {/* <th scope="col">Txn</th> */}
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tableRecords?.map((val, key) => {

                        return (
                          <tr>
                            <td>
                              <input
                                type="checkbox"
                                className="input__table_checkbox"
                                checked={selectedIds.filter(e => e.id == val?._id)?.length > 0 ? true : false}
                                onChange={(e) => handleCheckboxChange(val?._id, val?.payable_amount, val?.wallet_address, e.target.checked)}
                              />

                            </td>
                            <td>{val?.request_id}</td>

                            <td>{val?.username}</td>
                            <td>${val?.amount?.toFixed(2)}</td>
                            <td>${val?.deduction?.toFixed(2)}</td>
                            <td> ${val?.payable_amount?.toFixed(2)}</td>
                            <td>{dateformat(val?.created_at, "mmm dd yyyy h:MM:ss TT")}</td>
                            {/* <td>{val?.remark}</td> */}
                            {/* <td>{dateformat(val?.updated_at, "mmm dd yyyy h:MM:ss TT")}</td> */}
                            <td>{val?.wallet_address}</td>
                            {/* <td><a href={blackChainUrl + val?.hash} target="_blank">View TXN </a></td> */}
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
              {totalData > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                pageCount={totalData / 10}
                previousLabel="<"
              />}


              <div className="payout-off-on">
                <h6>Payout List Approve & Reject</h6>
                <div className="form-group">
                  <select onChange={(e) => setType(e.target.value)} className="form-select">
                    <option value="">Select Option</option>
                    <option value="true">Approve</option>
                    <option value="false">Reject</option>
                  </select>
                  <textarea className="form-control" id="exampleFormControlTextarea1" rows={3} onChange={(e) => setText(e.target.value)} value={text}></textarea>
                  <div>
                    <button type="button" className="btn btn-success" onClick={SubmitData}>Submit</button>

                    {/* <button type="button" className="btn btn-success" onClick={() => { approvedSubmit(["65b40fdeefe0cb9ae21aeee9"], '0xc08f22e1a58f16d56aec90c64d66670f616fd1164a1fe0b4bef88b1dc80ae1a0') }}> test Submit</button> */}

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PendingWithdraw;
