import React, { useEffect, useState } from "react";
// import { useState } from "../Assets/images/React App_files/bundle";

import _fetch from "../config/api";
import { api_url } from "../config/config";
import toasted from "../config/toast";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { AddCard, ArrowForward, } from "@mui/icons-material";
import Toggle from "./Components/Toggle";

const SendDeposit = (props: any) => {

  const [userName, setUserName] = useState();
  const [type, setType] = useState("Credit");
  const [amount, setAmount] = useState();

  const SubmitFund = async () => {
    let data = {
      "username": userName,
      "amount": amount,
      "credit_type": type
    }

    let res = await _fetch(`${api_url}fund/fundManage`, "POST", data)
    if (res?.status === "success") {
      toasted.success(res?.message);
    }
    else {
      toasted.error(res?.message);
    }
  }



  const handleTabSelect = (typeValue: any) => {

    setType(typeValue); // Update the selected type when a tab is clicked
  };


  const [allData, setAllData] = useState({ TotalDeposit: 0, allUsers: 0, AvaliableDeposit: 0 });

  useEffect(() => {
    getHomeData();
  }, []);

  const getHomeData = async () => {

    let res = await _fetch(`${api_url}home/dashboard`, "GET", {})
    if (res?.status === "success") {
      setAllData(res?.data);

    }
  }



  return (
    <div>
      <div>
        <div className="coustom_container">
          <div className="page_info">
            <div className="left__data">
              <h6> <AddCard /> {props.pageTitle}</h6>
              <p>Dashboard / Deposit / <span> {props.pageTitle}  </span></p>
            </div>

            <div className="right_data">
              <Toggle />
            </div>
          </div>

          <div className="edit__user">
            <div className="row">
              <div className="col-lg-6 col-md-8">
                <div className="card">

                  <Tabs>
                    <TabList>
                      <Tab
                        //  className="react-tabs__tab credit_btn "
                        className={`react-tabs__tab ${type === 'Credit' ? 'credit_btn' : ''}`}
                        onClick={() => handleTabSelect('Credit')}
                      >Credit</Tab>
                      <Tab
                        // className="react-tabs__tab  debit_btn" 
                        className={`react-tabs__tab ${type === 'Debit' ? 'debit_btn' : ''}`}
                        onClick={() => handleTabSelect('Debit')}>Debit</Tab>
                    </TabList>

                    <TabPanel>
                      <div className="inner_tabpanel">
                        <div className="__editgroup">
                          <label htmlFor="userID">User ID</label>
                          <input className="input-fields" id="userID" type="text" placeholder="User ID" onChange={(e: any) => setUserName(e.target.value)} value={userName} />
                        </div>

                        <div className="__editgroup">
                          <label htmlFor="amount">Amount</label>
                          <input className="input-fields" type="number" placeholder="Amount" onChange={(e: any) => setAmount(e.target.value)} value={amount} />
                        </div>

                        <div className="__editgroup m-0">
                          <button type="submit" className="button_form credit_btn_bg" onClick={SubmitFund}> Send </button>
                        </div>
                      </div>

                    </TabPanel>
                    <TabPanel>
                      <div className="inner_tabpanel">
                        <div className="__editgroup">
                          <label htmlFor="userID">User ID</label>
                          <input className="input-fields" type="text" placeholder="User ID" onChange={(e: any) => setUserName(e.target.value)} value={userName} />
                        </div>

                        <div className="__editgroup">
                          <label htmlFor="amount">Amount</label>
                          <input className="input-fields" type="number" id="amount" placeholder="Amount" onChange={(e: any) => setAmount(e.target.value)} value={amount} />
                        </div>

                        <div className="__editgroup m-0">
                          <button type="submit" className="button_form debit_btn_bg" onClick={SubmitFund}> Submit </button>
                        </div>
                      </div>
                    </TabPanel>
                  </Tabs>



                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default SendDeposit;
