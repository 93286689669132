import React, { useEffect, useState } from "react";
// import { useState } from "../Assets/images/React App_files/bundle";

import _fetch from "../config/api";
import { api_url } from "../config/config";
import toasted from "../config/toast";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { AddCard, ArrowForward, } from "@mui/icons-material";
import Toggle from "./Components/Toggle";
import { useParams } from "react-router-dom";
import { getData } from 'country-list';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Select, { SingleValue } from 'react-select';
const EditPassword = (props: any) => {
  const { user } = useParams();
  const [password, setpassword] = useState();


  const SubmitFund = async () => {
    let data = {
      "password": password,
      "username": user,
      

    }

    let res = await _fetch(`${api_url}auth/updateUserPassword`, "POST", data)
    if (res?.status === "success") {
      toasted.success(res?.message);
    }
    else {
      toasted.error(res?.message);
    }
  }



  const [allData, setAllData] = useState({ TotalDeposit: 0, allUsers: 0, AvaliableDeposit: 0 });

  useEffect(() => {
  }, []);



  type CountryOption = {
    value: string;
    label: string;
    flagUrl: string;
  };

  const CustomOption: React.FC<{ innerProps: any; label: string; data: CountryOption }> = ({ innerProps, label, data }) => (
    <div {...innerProps}>
      <img src={data.flagUrl} alt={label} style={{ marginRight: '8px' }} />
      {label}
    </div>
  );

  const countryOptions: CountryOption[] = getData().map((country: any) => ({
    value: country.code,
    label: country.name,
    flagUrl: `https://flagcdn.com/16x12/${country.code.toLowerCase()}.png`
  }));

  return (
    <div>
      <div>
        <div className="coustom_container">
          <div className="page_info">
            <div className="left__data">
              <h6> <AddCard /> {props.pageTitle}</h6>
              <p>Dashboard /User / <span> {props.pageTitle}  </span></p>
            </div>

            <div className="right_data">
              <Toggle />
            </div>
          </div>

          <div className="edit__user">
            <div className="row">
              <div className="col-lg-6 col-md-8">
                <div className="card">
                  <div className="__editgroup">
                    <label htmlFor="userID">Enter New Passowrd</label>
                    <input className="input-fields" id="userID" type="text" placeholder="Enter new password" onChange={(e: any) => setpassword(e.target.value)} value={password} />
                  </div>

                 




                  <div className="__editgroup m-0">
                    <button type="submit" className="btn btn__submit" onClick={SubmitFund}> Save </button>
                  </div>


                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
};

export default EditPassword;
