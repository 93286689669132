import React, { useEffect, useState } from "react";
import { Form } from "react-bootstrap";
import { AttachMoneyRounded, Badge, HomeTwoTone, Newspaper } from "@mui/icons-material";
import card_1 from "../Assets/images/sales-graph-1-1a977117-svg.svg";
import _fetch from "../config/api";
import { api_url } from "../config/config";
import dateformat from "dateformat";
import toasted from "../config/toast";
import LoadingBar from 'react-top-loading-bar';
import Toggle from "./Components/Toggle";


const UserPopupImage = (props:any) => {

  const [newsData, setNewsData] = useState<{ banner?: string, description?: string }>({});
  const [bannerImage, setBannerImage] = useState('');
  const [imagePath, setImagePath] = useState('');
  const [bannerDesc, setBannerDesc] = useState('');
  const [progress, setProgress] = useState(0);
  const [isTrue, setIsTrue] = useState(true);
  const [statusChange, setStatusChange] = useState(false);

  useEffect(() => {
    getBannerData();
  }, []);

  const getBannerData = async () => {

    setIsTrue(true);
    setProgress(10);

    let res = await _fetch(`${api_url}settings/userBanner`, "GET", {})
    if (res?.status === "success") {

      setProgress(50);
      setNewsData(res?.data[0]?.user_banner);
      setBannerImage(res?.data[0]?.user_banner?.banner);
      setBannerDesc(res?.data[0]?.user_banner?.description);
      setStatusChange(res?.data[0]?.user_banner?.banner_status || false);
      setProgress(100);
      setIsTrue(false);
    }
  }

  const SubmitUserPopup = async () => {

    let data = {
      "banner": bannerImage,
      "description": bannerDesc
    }
    let res = await _fetch(`${api_url}settings/setupUserBanner`, "POST", data)
    if (res?.status === "success") {
      toasted.success(res?.message);
      getBannerData();
    }
    else {
      toasted.error(res?.message);
    }
  }

  const handleStatusChange = () => {

    setStatusChange((prevStatus) => {
      const newStatus = !prevStatus;
      changeStatus(newStatus);

      return newStatus;
    });
  };

  const changeStatus = async (newStatus: boolean) => {

    let data = {
      "banner_status": newStatus
    }
    let res = await _fetch(`${api_url}settings/setupUserBannerStatus`, "POST", data)
    if (res?.status === "success") {
      toasted.success(res?.message);
    }
    else {
      toasted.error(res?.message);
    }
  }

  const handleImageChange = (e: any) => {
    const selectedImage = e.target.files[0];
    if (selectedImage) {
      const reader = new FileReader();

      reader.onloadend = () => {
        const base64Image = reader.result as string;;
        setBannerImage(base64Image);
      };

      reader.readAsDataURL(selectedImage);
    }
  };

  return (
    <>

      <LoadingBar
        color='var(--primary-color)'
        progress={progress}
        containerClassName="LoadingBar_contain"
        className="loaderbar-s"
        onLoaderFinished={() => setProgress(0)}
      />

      <div className={isTrue ? 'loader-bg' : ''}></div>

      <div className="d-details-user">
        <div className="coustom_container">

        <div className="page_info">
            <div className="left__data">
              <h6> <Badge /> {props.pageTitle}</h6>
              <p>Dashboard / Settings / <span> {props.pageTitle}  </span></p>
            </div>

            <div className="right_data">
              <Toggle />
            </div>
          </div>

          <div className="tabl-news-card">
            <div className="row m-0">
              <div className="col-md-4">

                <div className="card first-card">
                  <div className="card-header">
                    <div className="card-imgs">
                      <img src={card_1} alt="icon" />
                      <AttachMoneyRounded className="icon payout" />
                    </div>
                    <div className="card-ryt-text">
                      <p>Update User Banner</p>
                    </div>
                  </div>

                  <div className="">
                    <form>
                      <div className="form-group mb-3 mt-3">
                        <label htmlFor="newsTItle">Banner</label>
                        <input type="file" className="form-control" id="newsTItle" name="newsTItle" onChange={(e: any) => { handleImageChange(e); setImagePath((URL.createObjectURL(e.target.files[0]))) }} />
                      </div>

                      <div className="form-group mt-3 mb-3">
                        <label htmlFor="News">Description</label>
                        <textarea className="form-control" id="exampleFormControlTextarea1" rows={3} onChange={(e: any) => setBannerDesc(e.target.value)} value={bannerDesc}></textarea>
                      </div>

                      <div className="">
                        <button type="button" className="btn btn-news" onClick={SubmitUserPopup}>Submit</button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-md-8">
                <div className="outer-table table-responsive ps-md-4">
                  <div className="t-head">
                    <div className="t-head-left">
                      <h5>Banner Display</h5>
                      {/* <Form>
                        <Form.Check type="switch" id="custom-switch" label="Show Banner on User Panel. " onChange={(e: any) => handleStatusChange()} checked={statusChange} />
                      </Form> */}

                      <div className="toggle-sec">
                        <label className="switch">
                          <input type="checkbox" className="switch-input" onChange={(e: any) => handleStatusChange()} checked={statusChange} />
                          <span className="switch-label" data-on="ON" data-off="OFF"></span>
                          <span className="switch-handle"></span>
                        </label>
                      </div>
                    </div>

                  </div>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>Description</th>
                        <th>Banner</th>
                      </tr>
                    </thead>
                    <tbody>

                      <tr>
                        <td>
                          <div className="">
                            <p>{newsData?.description}</p>
                          </div>
                        </td>
                        <td className="date-sec text-nowrap">
                          <img style={{ width: "103px" }} src={(newsData?.banner)} />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserPopupImage;
