import React, { useEffect, useState } from "react";
import _fetch from "../config/api";
import { api_url } from "../config/config";
import dateformat from "dateformat";
import ReactPaginate from "react-paginate";
import card_1 from "../Assets/images/sales-graph-1-1a977117-svg.svg";
import { FiberManualRecord, PersonRounded } from "@mui/icons-material";
import LoadingBar from 'react-top-loading-bar';
import { Form } from "react-bootstrap";
import toasted from "../config/toast";
import Toggle from "./Components/Toggle";

const ROIHistory = (props: any) => {

  const [searchedValue, setSearchedValue] = useState("");
  const [searchedType, setSearchedType] = useState("username");
  const [tableRecords, settableRecords] = useState([]);
  const [itemOffset, setItemOffset] = useState(1);
  const [allDetail, setAllDetail] = useState({ totalInvestment: "", todayInvestment: "", totalRoi: "" });
  const [totalData, setTotalData] = useState(0);
  const [sort, setSort] = useState('created_at');
  const [limit, setLimit] = useState(10);
  const [keyCount, setKeycount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isTrue, setIsTrue] = useState(true);
  const [check, setcheck] = useState(false);
  const [statusChange, setStatusChange] = useState(false);


  useEffect(() => {
    getData();
  }, [searchedType, sort, searchedValue, itemOffset, limit]);

  async function getData() {

    setIsTrue(true);
    setProgress(10);

    let searchData = '';
    if (searchedValue !== "" && searchedValue != undefined) {
      searchData = `&search=${searchedType}&value=${searchedValue}`;
    }
    else {
      searchData = "";
    }
    if (searchData) {
      const Data = await _fetch(`${api_url}income/roiHistory?sort=${sort}${searchData}`, 'GET', {});

      if (Data?.status === "success") {

        // setProgress(50);
        settableRecords(Data?.data?.data);
        setAllDetail(Data);
        setTotalData(Data?.total);
        setProgress(100);
        setIsTrue(false);
      }
      if (itemOffset > 1) {
        setKeycount(0);
        var count = itemOffset - 1;
        setKeycount(count * limit);
      }
    }
    else {
      const Data = await _fetch(`${api_url}income/roiHistory?sort=${sort}&page=${itemOffset}&limit=${limit}${searchData}`, 'GET', {});


      if (Data?.status === "success") {

        // setProgress(50);
        settableRecords(Data?.data?.data);
        setAllDetail(Data);
        setTotalData(Data?.total);
        setProgress(100);
        setIsTrue(false);
      }
      if (itemOffset > 1) {
        setKeycount(0);
        var count = itemOffset - 1;
        setKeycount(count * limit);
      }
    }
  }

  const handlePageClick = async (event: any) => {
    const newOffset = (event.selected + 1);
    setItemOffset(newOffset);
  };

  const handleStatusChange = async (id: any, currentStatus: any) => {
    const newStatus = !currentStatus;

    const data = {
      "_id": id,
      "roi_process": newStatus,
    };

    const res = await _fetch(`${api_url}settings/roiAccess`, "POST", data);

    if (res?.status === "success") {
      toasted.success(res?.message);
      getData();
    } else {
      toasted.error(res?.message);
    }
  };

  return (
    <div>

      <LoadingBar
        color='var(--primary-color)'
        progress={progress}
        containerClassName="LoadingBar_contain"
        className="loaderbar-s"
        onLoaderFinished={() => setProgress(0)}
      />

      <div className={isTrue ? 'loader-bg' : ''}></div>

      <div className="table-sec pb-4">
        <div className="table-sec-subheader">

          {/* user details...  */}

          <div className="details-sec">
            <div className="coustom_container">

              <div className="page_info">
                <div className="left__data">
                  <h6> <PersonRounded /> {props.pageTitle}</h6>
                  <p>Dashboard / Settings / <span> {props.pageTitle}  </span></p>
                </div>

                <div className="right_data">
                  <Toggle />
                </div>
              </div>

              <div className="row justify-content-center">
                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Total Investment
                      </p>
                    </div>
                    <div className="text-start pt-1">
                      <h4 className="active-txt"> <span className="de-sc"></span> ${Number(allDetail?.totalInvestment)?.toFixed(2)}</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Today Investment
                      </p>
                    </div>
                    <div className="text-start pt-1">
                      <h4 className="text-success"> <span className="de-sc"></span> ${Number(allDetail?.todayInvestment)?.toFixed(2)}</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Total ROI
                      </p>
                    </div>
                    <div className="text-start pt-1">
                      <h4 className="text-warning"> <span className="de-sc"></span> ${Number(allDetail?.totalRoi)?.toFixed(2)} </h4>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>




        <div className="coustom_container mb-5">
          <div className="table_main table_main_new">
            <div className="heading">
              <h1>All ROI History</h1>
              <form action="">
                <div className="form-group">
                  <select className="form-select" onChange={(e: any) => setSearchedType(e.target.value)}>
                    <option value={'username'}>Username</option>
                  </select>
                </div>
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="search"
                    placeholder="Search"
                    onChange={(e: any) => setSearchedValue(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="table-sec-new">
              <div className="outer-table table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">#</th>
                      <th scope="col"></th>
                      <th scope="col">Username</th>
                      <th scope="col">Amount</th>
                      <th scope="col">ROI</th>
                      <th scope="col">Left Days</th>
                      <th scope="col">Total Days</th>
                      <th scope="col">Stake At</th>
                      <th scope="col">Unstake At</th>
                      <th scope="col">Invest At</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tableRecords?.map((val: any, key: any) => {
                        return (
                          <tr>
                            <td>{itemOffset > 1 ? key + keyCount + 1 : key + 1}</td>
                            <td>
                              <Form>
                                <Form.Check type="switch" id="custom-switch" onChange={(e: any) => { handleStatusChange(val?._id, val?.roi_process); setStatusChange(val?.roi_process) }} checked={val?.roi_process} />
                              </Form>
                            </td>
                            <td>{val?.username}</td>
                            <td>${val?.amount?.toFixed(2)}</td>
                            <td>{val?.roi}</td>
                            <td>{val?.days}</td>
                            <td>{val?.total_days}</td>
                            <td>{dateformat(val?.investDate, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>
                            <td>{dateformat(val?.unStakeDate, "UTC:dd-mm-yyyy hh:mm:ss TT")}</td>
                            <td> {dateformat(val?.created_at, "UTC:dd-mm-yyyy hh:mm:ss TT")}
                            </td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
              {totalData > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                pageCount={totalData / 10}
                previousLabel="<"
              />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ROIHistory;
