import React, { useEffect, useState } from "react";
import _fetch from "../config/api";
import { api_url, blackChainUrl } from "../config/config";
import dateformat from "dateformat";
import ReactPaginate from "react-paginate";
import card_1 from "../Assets/images/sales-graph-1-1a977117-svg.svg";
import { FiberManualRecord, PersonRounded } from "@mui/icons-material";
import LoadingBar from 'react-top-loading-bar';
import { useParams } from 'react-router-dom';
import { Badge } from "react-bootstrap";
import Toggle from "./Components/Toggle";


const WithdrawRequest = (props) => {

  const [searchedValue, setSearchedValue] = useState("");
  const [searchedType, setSearchedType] = useState("username");
  const [tableRecords, settableRecords] = useState([]);
  const [itemOffset, setItemOffset] = useState(1);
  const [allDetail, setAllDetail] = useState({ totalWithdraw: "", todayWithdrawAmount: "", totalApprovedWithdraw: "", totalPendingWithdraw: "" });
  const [totalData, setTotalData] = useState(0);
  const [sort, setSort] = useState('created_at');
  const [limit, setLimit] = useState(10);
  const [keyCount, setKeycount] = useState(0);
  const [progress, setProgress] = useState(0);
  const [isTrue, setIsTrue] = useState(true);
  const [type, setType] = useState(false);
  const [text, setText] = useState('');
  const [selectedIds, setSelectedIds] = useState('');



  useEffect(() => {
    getData();
  }, [searchedType, sort, searchedValue, itemOffset, limit]);

  async function getData() {

    setIsTrue(true);
    setProgress(10);

    let searchData = '';
    if (searchedValue !== "" && searchedValue != undefined) {
      searchData = `&search=${searchedType}&value=${searchedValue}`;
    }
    else {
      searchData = "";

    }

    const Data = await _fetch(`${api_url}payout/allPayouts?sort=${sort}&page=${itemOffset}&limit=${limit}${searchData}`, 'GET', {});
    if (Data?.status === "success") {

      settableRecords(Data?.data?.data);
      setAllDetail(Data);
      setTotalData(Data?.total);
      setProgress(100);
      setIsTrue(false);
    }
    if (itemOffset > 1) {
      setKeycount(0);
      var count = itemOffset - 1;
      setKeycount(count * limit);
    }
  }

  const handlePageClick = async (event) => {
    const newOffset = (event.selected + 1);
    setItemOffset(newOffset);
  };


  const SubmitData = async () => {
    let data = {
      "_id": selectedIds,
      "withdraw_status": type,
      "remark": text
    }
    let res = await _fetch(`${api_url}payout/payoutAccessAdmin`, "POST", data)
    if (res?.status === 'success') {
      // toasted.success(res?.message);
    }
    else {
      // toasted.error(res?.message);
    }
  }



  return (
    <div>

      <LoadingBar
        color='var(--primary-color)'
        progress={progress}
        containerClassName="LoadingBar_contain"
        className="loaderbar-s"
        onLoaderFinished={() => setProgress(0)}
      />

      <div className={isTrue ? 'loader-bg' : ''}></div>

      <div className="table-sec pb-4">
        <div className="table-sec-subheader">

          {/* user details...  */}

          <div className="details-sec">
            <div className="coustom_container">

            <div className="page_info">
                <div className="left__data">
                  <h6> <PersonRounded /> {props.pageTitle}</h6>
                  <p>Dashboard / Withdraw / <span> {props.pageTitle}  </span></p>
                </div>

                <div className="right_data">
                  <Toggle />
                </div>
              </div>
              
              <div className="row">
                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Total Payout
                      </p>
                    </div>
                    <div className="text-start">
                      <h4 className="active-txt"> <span className="de-sc"></span> ${Number(allDetail?.totalWithdraw)?.toFixed(2)}</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Today Payout
                      </p>
                    </div>
                    <div className="text-start bg-info">
                      <h4> <span className="de-sc"></span> ${Number(allDetail?.todayWithdrawAmount)?.toFixed(2)} </h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Approved Payout
                      </p>
                    </div>
                    <div className="text-start bg-success">
                      <h4> <span className="de-sc"></span> ${Number(allDetail?.totalApprovedWithdraw)?.toFixed(2)}</h4>
                    </div>
                  </div>
                </div>

                <div className="col-lg-3 col-sm-6">
                  <div className="card table-details">
                    <div className="card-header">
                      <div className="card-imgs">
                        <img src={card_1} alt="icon-bg" />
                        <PersonRounded className="icon user" />
                      </div>
                      <p className="text-sm mb-0 text-capitalize">
                        Pending Payout
                      </p>
                    </div>
                    <div className="text-start bg-warning">
                      <h4> <span className="de-sc"></span> ${Number(allDetail?.totalPendingWithdraw)?.toFixed(2)} </h4>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>




        <div className="coustom_container mb-5">
          <div className="table_main table_main_new">
            <div className="heading">
              <h1>All Payout List</h1>
              <form action="">
                <div className="form-group">
                  <select className="form-select" onChange={(e) => setSearchedType(e.target.value)}>
                    <option value={'username'}>Username</option>
                  </select>
                </div>
                <div className="form-group">
                  <input
                    className="input-fields"
                    type="search"
                    placeholder="Search"
                    onChange={(e) => setSearchedValue(e.target.value)}
                  />
                </div>
              </form>
            </div>
            <div className="table-sec-new">
              <div className="outer-table table-responsive">
                <table className="table">
                  <thead>
                    <tr>
                      <th scope="col">Request No.</th>
                      <th scope="col">Username</th>
                      <th scope="col">Amount</th>
                      <th scope="col">Deduction</th>
                      <th scope="col">Payable Amount</th>
                      <th scope="col">Status</th>

                      <th scope="col"> Date</th>
                      <th scope="col">Remark</th>
                      <th scope="col">Action Date</th>
                      <th scope="col">Wallet Address</th>
                      <th scope="col">Txn</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      tableRecords?.map((val, key) => {
                        return (
                          <tr>


                            <td>{val?.request_id}</td>

                            <td>{val?.username}</td>
                            <td>${val?.amount?.toFixed(2)}</td>
                            <td>${val?.deduction?.toFixed(2)}</td>
                            <td> ${val?.payable_amount?.toFixed(2)}</td>
                            <td>
                              {val?.status == 'Approve' &&   <Badge bg="success">{val?.status}</Badge>}
                              {val?.status == 'Pending' &&   <Badge bg="warning">{val?.status}</Badge>}
                              {val?.status == 'Reject' &&   <Badge bg="danger">{val?.status}</Badge>}
                            </td>

                            <td>{dateformat(val?.created_at, "mmm dd yyyy h:MM:ss TT")}</td>
                            <td>{val?.remark}</td>
                            <td>{dateformat(val?.updated_at, "mmm dd yyyy h:MM:ss TT")}</td>
                            <td>{val?.wallet_address}</td>
                            <td><a href={blackChainUrl + val?.hash} target="_blank">View TXN </a></td>
                          </tr>
                        );
                      })
                    }
                  </tbody>
                </table>
              </div>
              {totalData > 10 && <ReactPaginate className="pagination__2 " activeClassName="active"
                breakLabel="..."
                nextLabel=">"
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                pageCount={totalData / 10}
                previousLabel="<"
              />}


              

            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WithdrawRequest;
